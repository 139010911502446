<template>
  <div class="album">
    <!-- <radio /> -->
    <helloWorld />
    <div class="content-920" v-show="show==true">
      <p class="upload-text">创建专辑</p>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="专辑名称" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入专辑名称"></el-input>
        </el-form-item>
        <el-form-item prop="coverPic">
          <a><span style="color:#F56C6C">*</span> 专辑封面</a>
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeAvatarUpload">
            <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar-a"> -->
            <el-image
              v-if="imageUrl"
              :src="imageUrl"
              class="avatar-a"
              fit="contain"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input type="textarea" :rows="2" v-model="ruleForm.desc" placeholder="请输入专辑描述"></el-input>
        </el-form-item>
        <el-form-item style="text-align:center">
          <el-button type="primary" size="small" @click="submitForm('ruleForm')">创建专辑</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-920" v-show="show==false">
      <p class="upload-text">创建作品</p>
      <el-form :model="Form" :rules="rule" ref="Form" label-width="100px" class="demo-ruleForm">
        <el-form-item label="所属专辑" class="contet-creator" prop="albumId">
          <el-select v-model="Form.albumId" placeholder="请选择" style="width:300px">
            <el-option
              v-for="item in albumList"
              :key="item.id"
              :label="item.title"
              :value="Number(item.id)">
            </el-option>
          </el-select>
          <el-button style="margin-left:10px" @click="toCreat">创建新专辑</el-button>
        </el-form-item>
        <el-form-item label="作品名称" prop="colName">
          <el-input v-model="Form.colName" placeholder="请输入作品名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="typeId">
          <el-select v-model="Form.typeId" placeholder="请选择类型" @change="chooseType" style="width:300px">
            <el-option
              v-for="item in typelist"
              :key="item.id"
              :label="item.typeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类" prop="subtypeId">
          <el-select v-model="Form.subtypeId" placeholder="请选择分类" style="width:300px">
            <el-option
              v-for="item in itemWrap"
              :key="item.id"
              :label="item.typeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="totalNum">
          <el-input v-model.number="Form.totalNum" placeholder="请输入藏品数量，数量区间1-9999" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item>
          <a><span style="color:#F56C6C">*</span> 作品</a>
          <el-upload
            class="avatar-uploader"
            action="1"
            :show-file-list="false"
            :http-request="uploadFile1"
            :before-upload="beforeAvatarUpload1">
            <!-- <img v-if="imageUrl1" :src="imageUrl1" class="avatar-a"> -->
            <el-image
              v-if="imageUrl1"
              :src="imageUrl1"
              class="avatar-a"
              fit="contain"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item class="enclosure">
          <a><span style="color:#F56C6C">*</span> 上传原文件及版权证明</a>
          <el-upload
            class="upload-demo"
            action="1"
            :http-request="uploads">
            <el-button size="small" type="" icon="el-icon-upload2">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="line-height:20px;letter-spacing:1px;max-width:480px">请上传藏品源文件以及其他与该藏品版权证明相关的文件（如原创设计手稿，购买记录，专利证明等），确保您的版权权益；相关文件仅用于后台审核</div>
          </el-upload>
          <div class="proof-list">
            <div class="sure" v-for="(item, index) in openList" :key="index">
              <!-- <img :src="item" alt=""> -->
              <!-- <el-image
                v-if="item.type== 'image/jpeg' || item.type=='image/jpg' || item.type=='image/png'|| item.type=='image/bmp'||item.type=='image/gif'"
                :src="item.img+'?scene=collect&token='+accessToken"
                fit="contain">
              </el-image> -->
              <img src="@/assets/file.png" alt="">
              <!-- <el-image
                v-else
                src="@/assets/file.png"
                fit="contain">
              </el-image> -->
              <div class="remove-btn" @click="removeFile(index)">
                <i class="el-icon-delete" style="color:#fff"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="描述" prop="content">
          <el-input type="textarea" :rows="2" v-model="Form.content" placeholder="请输入专辑描述" style="width:300px"></el-input>
        </el-form-item>
        <div class="rule">
          <el-radio v-model="radio" label="1">我已仔细阅读并同意</el-radio>
          <a target="blank" href="#/rule" @click.stop="toRule" style="color: #0091ff !important;">《数字藏品铸造守则》</a>
        </div>
        <el-form-item style="text-align:center">
          <el-button type="primary" size="small" @click="release('Form')">发布</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { uploadImgSafe, getAlbumList, getType, addAlbum, addCollect, getUpload, downImg, getEditDetail, editCollect } from '../../api/index'
import helloWorld from '@/components/tabBar'
import { mapGetters } from 'vuex'
// import radio from '@/components/radio'
export default {
  name: 'Index',
  components: {
    helloWorld,
    // radio
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.getTypeList()
    this.getAlbum()
    if (this.$route.query.colCode) {
      this.colCode = this.$route.query.colCode
      this.getCollectInfo()
    }
  },
  data() {
    return {
      colCode: '',
      env: process.env.VUE_APP_BASE_API,
      fileList: [],
      ruleForm: {
        title: '',
        desc: '',
        coverPic: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入专辑名称', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ]
      },
      Form: {
      },
      rule: {
        albumId: [
          { required: true, message: '请选择所属专辑', trigger: 'change' }
        ],
        colName: [
          { required: true, message: '请输入作品名称', trigger: 'blur' }
        ],
        typeId: [
          { required: true, message: '请选择所属类型', trigger: 'change' }
        ],
        subtypeId: [
          { required: true, message: '请选择所属分类', trigger: 'change' }
        ],
        totalNum: [
          { required: true, message: '请输入数量', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: this.validNum }
        ],
        content: [
          { required: true, message: '请输入作品描述', trigger: 'blur' }
        ]
      },
      radio: '',
      imageUrl: '',
      imageUrl1: '',
      title: '',
      desc: '',
      show: false,
      itemWrap:[],
      valP: '',
      valT: '',
      options: [],
      value: '',
      typelist: [],
      albumList: [],
      subList: [],
      openList: [],
      fileObj: {},
      getImgSrc: '',
      getPic: ''
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  methods: {
    // 获取藏品详情
    getCollectInfo() {
      this.subList= []
      this.openList= []
      let obj = {
        colCode: this.colCode
      }
      getEditDetail(obj).then(res => {
        if (res.code == 0) {
          this.Form = res.data
          this.getPic = res.data.pic
          let a = {
            path: res.data.pic
          }
          downImg(a).then(res => {
            let blob = new Blob([res]);   // 返回的文件流数据
            let url = window.URL.createObjectURL(blob);  // 将他转化为路径
            this.imageUrl1 = url;
          })
          // this.subList = res.data.source
          for (var h = 0; h < res.data.source.length; h++) {
            this.subList.push(res.data.source[h].path)
            this.openList.push(res.data.source[h].path)
          }
          let data = {
            pid: res.data.typeId
          }
          getType(data).then(res => {
            if (res.code === 0) {
              this.itemWrap = res.data.list
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    removeFile(index) {
      this.subList.splice(index,1)
      this.openList.splice(index,1)
    },
    validNum(rule, value,callback){
      var reg=/^[1-9][0-9]{0,3}$/;
      if (!value){
        callback(new Error('请输入数量'))
      }else  if(!reg.test(value)){
        callback(new Error('请输入1-9999的正整数'));
      } else {
        callback()
      }
    },
    toCreat() {
      if (this.$refs.ruleForm !== undefined) {
        this.$refs.ruleForm.resetFields();
      }
      this.imageUrl = ''
      // this.ruleForm = {
      //   title: '',
      //   desc: '',
      //   coverPic: ''
      // }
      this.show = true
    },
    creatAlbum() {
      this.show = true
    },
    uploads(param) {
      const fileObj = param.file
      const data = new FormData()
      const type = param.file.type
      data.append('file', fileObj)
      data.append('scene', 'collect')
      getUpload(data)
        .then((res) => {
          if (res.code === 0) {
            let data = {
              type: type,
              img: res.data.fileUrl
            }
            this.openList.push(data)
            this.subList.push(res.data.path)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    uploadFile1(param) {
      const fileObj = param.file
      const data = new FormData()
      data.append('file', fileObj)
      uploadImgSafe(data)
        .then((res) => {
          if (res.code === 0) {
            this.getPic = res.data.path
            let a = {
              path: res.data.path
            }
            downImg(a).then(res => {
              let blob = new Blob([res]);   // 返回的文件流数据
              let url = window.URL.createObjectURL(blob);  // 将他转化为路径
              this.imageUrl1 = url;
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    beforeAvatarUpload1(file) {
      const isJPG = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/jpg'];
      const isImage = isJPG.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isImage) {
        this.$message.error('请按规格上传!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    getTypeList() {
      getType().then(res => {
        if (res.code == 0) {
          this.typelist = res.data.list
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAlbum() {
      getAlbumList().then(res => {
        if (res.code === 0) {
          if (res.data.total == 0) {
            this.show = true
          } else {
            this.show = false
            this.albumList = res.data.list
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    uploadFile(param) {
      const fileObj = param.file
      const data = new FormData()
      data.append('file', fileObj)
      uploadImgSafe(data)
        .then((res) => {
          if (res.code === 0) {
            this.getImgSrc = res.data.path
            let a = {
              path: res.data.path
            }
            downImg(a).then(res => {
              console.log(res)
              // this.imageUrl = res
              let blob = new Blob([res]);   // 返回的文件流数据
              let url = window.URL.createObjectURL(blob);  // 将他转化为路径
              this.imageUrl = url;
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    beforeAvatarUpload(file) {
      const isJPG = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/jpg'];
      const isImage = isJPG.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isImage) {
        this.$message.error('请按规格上传!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.coverPic = this.getImgSrc
          addAlbum(this.ruleForm).then(res => {
            if (res.code ===0) {
              if (this.$refs.Form !== undefined) {
                this.$refs.Form.resetFields();
              }
              this.Form = {}
              this.imageUrl1 = ''
              this.show = false
              this.radio = ''
              this.getAlbum()
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    release(form){
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.getPic == '') {
            this.$message.error('请上传作品');
            return
          }
          if (this.subList.length ==0 ) {
            this.$message.error('请上传原文件及版权证明');
            return
          }
          if(this.radio === '') {
            this.$message.error('请勾选数字藏品铸造守则');
            return
          }
          this.Form.pic = this.getPic
          this.Form.source = this.subList
          if(this.$route.query.colCode) {
            this.Form.colCode = this.colCode
            editCollect(this.Form).then(res => {
              if (res.code ===0) {
                this.$message.success('作品修改成功');
                this.$router.push('./radicalDreamer')
                this.subList = []
                this.openList = []
              } else {
                this.$message.error(res.message);
              }
            })
          } else {
            addCollect(this.Form).then(res => {
              if (res.code ===0) {
                this.$message.success('作品添加成功');
                this.$router.push('./radicalDreamer')
                this.subList = []
                this.openList = []
                // this.$router.push('./detail?colCode='+res.data.colCode)
                // if (this.$refs.Form !== undefined) {
                //   this.$refs.Form.resetFields();
                // }
                // this.Form = {}
                // this.imageUrl1 = ''
                // this.show = false
                // this.getAlbum()
              } else {
                this.$message.error(res.message);
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    chooseType(val) {
      let data = {
        pid: val
      }
      getType(data).then(res => {
        if (res.code === 0) {
          this.itemWrap = res.data.list
          this.$set(this.Form, 'subtypeId', '')
          this.$nextTick(function() {
            const arr = ['subtypeId']
            this.$refs['Form'].clearValidate(arr)
          })
        } else {
          this.$message.error(res.message)
        }
        // this.itemWrap = res.data
        // console.log(res)
      })
    },
    toRule() {
      // this.$router.push('./rule')
    }
  }
}
</script>
<style scoped>
.proof-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.remove-btn {
  position: absolute;
  bottom: 4px;
  right: 6px;
}
.sure {
  width: 100px;
  height: 100px;
  position: relative;
  margin-left: 10px;
  margin-top: 4px;
}
.sure .el-image {
  max-width: 100%;
  height: 100%;
}
.sure img {
  max-width: 100%;
  height: 100%;
}
.remove-btn {
  background: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  padding: 0 2px;
}
.rule {
  padding: 12px 0;
  font-size: 12px;
}
.rule span {
  color: rgb(61, 97, 255);
  font-size: 12px;
  cursor: pointer;
}
.rule span:hover {
  text-decoration:underline;
}
.content-920 {
  max-width: 500px;
  margin: auto;
  padding-top: 20px;
}
.upload-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.upload-type {
  color: rgb(53, 56, 64);
  font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
}
.upload-type span {
  color: red;
}
</style>
<style>
.enclosure .el-form-item__content {
  max-width: 500px !important;
}
.enclosure .el-upload-list {
  display: none;
}
.rule .el-radio__label {
  color: #000 !important;
}
.contet-creator .el-form-item__content {
  max-width: 480px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar-a {
    width: 178px;
    height: 178px;
    display: block;
  }
.album label{
  font-size: 14px;
  color: #000;
  /* margin-top: 20px; */
}
.rule .el-radio {
  margin-right: 0;
}
.avatar-uploader {
  margin-top: 10px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>